// src/components/Header.js
import React from 'react';
import './Header.css';
import './Boton.css'
//<a href="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+Tardes+quiero+conocer+mas+de+sus+servicios." target="_blank" className="cta-button btnColor">Cotizar</a>

const Header = () => {
  return (
    <header className="header" >
      <div className="banner">
        <h1>Ingeniería Integral Mantenimiento y Seguridad en Alturas</h1>
        <p>Ofrecemos soluciones integrales en seguridad en alturas y mantenimiento de infraestructuras, respondiendo a tus necesidades con propuestas de valor precisas y efectivas.</p>
      </div>
    </header>
  );
};

export default Header;


import { Route, Routes } from 'react-router-dom';
import Layout from './pages/menu';
import Inicio from './pages/inicio';
import ObrasCiviles from './pages/ObrasCiviles';
import ServiciosDeIngenieria from './pages/ServiciosDeIngenieria';
import SeguridadEnAlturas from './pages/SeguridadEnAlturas'
import Contacto from './pages/Contacto'
import PuntosDeAnclaje from './pages/puntosdeanclaje'

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout/>}>
        <Route path="/" element={<Inicio/>}/>
        <Route path="/ObrasCiviles" element={<ObrasCiviles/>}/>
        <Route path='/ServiciosDeIngenieria' element={<ServiciosDeIngenieria/>}/>
        <Route path='/SeguridadEnAlturas' element={<SeguridadEnAlturas/>}/>
        <Route path='/Contacto' element={<Contacto/>}/>
        <Route path='/Puntos-De-Anclaje' element={<PuntosDeAnclaje/>}/>
        <Route path='/*' element={<Contacto/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import Contacto from "../component/Divi2Contacto"
import Footer from '../component/Footer';
import IconoWhatsapp from "../component/IconoWhatsapp";
import { Helmet } from "react-helmet";

const contacto = () =>{
    console.log('Componente montadoedse');
    return <div>
        <Helmet>
          <title>Contacto para Soluciones de Seguridad: Consultoría e Ingeniería a tu Alcance</title>
          <link rel="canonical" href="http://industek.com.co/contacto"></link>
          <meta property="og:url" content="http://industek.com.co/contacto"></meta>
          <meta itemprop="url" content="http://industek.com.co/contacto"></meta>
          <meta name="twitter:url" content="http://industek.com.co/contacto"></meta>
          <meta name="description" content="¡Contáctanos y Accede a una Consultoría Especializada en Proyectos de Ingeniería! Ofrecemos Servicios Integrales en Líneas de Vida y Puntos de Anclaje Seguros. ¡Déjanos ser tu aliado en el éxito de tus proyectos! Contáctanos ahora mismo."></meta>
          <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
          <meta property="og:title" content="Contacto para Soluciones de Seguridad: Consultoría e Ingeniería a tu Alcance"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:description" content="¡Contáctanos y Accede a una Consultoría Especializada en Proyectos de Ingeniería! Ofrecemos Servicios Integrales en Líneas de Vida y Puntos de Anclaje Seguros. ¡Déjanos ser tu aliado en el éxito de tus proyectos! Contáctanos ahora mismo."></meta>
          <meta itemprop="name" content="Contacto para Soluciones de Seguridad: Consultoría e Ingeniería a tu Alcance"></meta>
          <meta itemprop="description" content="¡Contáctanos y Accede a una Consultoría Especializada en Proyectos de Ingeniería! Ofrecemos Servicios Integrales en Líneas de Vida y Puntos de Anclaje Seguros. ¡Déjanos ser tu aliado en el éxito de tus proyectos! Contáctanos ahora mismo."></meta>
          <meta name="twitter:title" content="Contacto para Soluciones de Seguridad: Consultoría e Ingeniería a tu Alcance"></meta>
          <meta name="twitter:card" content="summary"></meta>
          <meta name="twitter:description" content="¡Contáctanos y Accede a una Consultoría Especializada en Proyectos de Ingeniería! Ofrecemos Servicios Integrales en Líneas de Vida y Puntos de Anclaje Seguros. ¡Déjanos ser tu aliado en el éxito de tus proyectos! Contáctanos ahora mismo."></meta>

        </Helmet>
        <Contacto />
        <Footer />
        <IconoWhatsapp/>
    </div>
}
export default contacto
import React from 'react';
import './TextoCentralBtn.css';

const IndustekSection = ({ title, subtitle, description, question, buttonText }) => {
  return (
    <div className="industek-section-container">
      <h1 className="industek-title">{title}</h1>
      <h2 className="industek-subtitle">{subtitle}</h2>
      <p className="industek-description">{description}</p>
      <p className="industek-question">{question}</p>
      <a href='https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+Dias+Industek+visite+su+pagina+quiero+conocer+mas+de+sus+servicios.' className="industek-button btnColor" target="_blank">{buttonText}</a>
    </div>
  );
}

export default IndustekSection;


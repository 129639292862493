import React from 'react';
import './SeccionMitadImagenDERTxTCentrado.css';

const SeccionConImagen = ({ imagenURL, titulo, descripcion, textoBoton, url }) => {
  return (
    <div className="contenedor-seccion-TxT-Centrado-LadoDER">
      <div className="lado-izquierdo-TxT-Centrado-LadoDER">
        <img src={imagenURL} alt={titulo} className="imagen-seccion-TxT-Centrado-LadoDER" />
      </div>
      <div className="lado-derecho-TxT-Centrado-LadoDER">
        <h2 className="titulo-seccion-TxT-Centrado-LadoDER">{titulo}</h2>
        <p className="descripcion-seccion-TxT-Centrado-LadoDER">{descripcion}</p>
        <div>
            <a className='boton-seccion-TxT-Centrado-LadoDER btnColor' type="button" href={url}>{textoBoton}</a>
        </div>
      </div>
    </div>
  );
};

export default SeccionConImagen;
import React from 'react';
import './TxTCentrado.css'; // Asegúrate de que este archivo esté correctamente enlazado
import './TxTCentradoColor.css'

const IndustekSection = ({titulo, empresa, descripcion}) => {
  return (
    <div className="contenedor-industek-TxTCentradoColor">
      <h1 className="titulo-pequeno-TxTCentrado">{empresa}</h1>
      <h2 className="titulo-grande-TxTCentrado">{titulo}</h2>
      <p className="descripcion-TxTCentradoColor">{descripcion}
      </p>
    </div>
  );
};

export default IndustekSection;
import Header from "../component/Header";
import Footer from '../component/Footer';
import GridContent from '../component/gridtreselementos';
import BannerImageIzq from '../component/bannerImageIzqui'
import BannerImageDer from '../component/BannerDerImage'
import TextCentralColor from '../component/CentralTxTColor'
import IndustekSection from '../component/TextoCentralBtn';
import IconoWhatsapp from "../component/IconoWhatsapp";
import { Helmet } from 'react-helmet'

const Inicio = () => {
    return <div>
      <Helmet>
        <title>Industek Seguridad en Alturas, lineas de vida, puntos de anclaje</title>
        <meta name="description" content="Nuestra empresa se especializa en la venta de servicios de ingeniería enfocados en líneas de vida y puntos de anclaje.ofrecemos una amplia gama, puntos de anclaje, lineas de vida, linea de vida horizontal, linea de vida vertical, línea de vida, lineas de vida verticales puntos de anclaje certificados" />
        <meta name="keywords" content="Ingeniería, Obras Civiles, Proyectos, Construcción, Servicios de ingeniería" />
        
        <link rel="canonical" href="https://www.industek.com.co/"></link>
        <meta property="og:url" content="https://www.industek.com.co/"></meta>
        <meta itemprop="url" content="https://www.industek.com.co/"></meta>

        
        <meta name="twitter:url" content="https://www.industek.com.co/"></meta>
        <meta name="description" content="Nuestra empresa se especializa en la venta de servicios de ingeniería enfocados en líneas de vida y puntos de anclaje.ofrecemos una amplia gama, puntos de anclaje, lineas de vida, linea de vida horizontal, linea de vida vertical, línea de vida, lineas de vida verticales puntos de anclaje certificados"></meta>
        <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
        <meta property="og:title" content="Industek Seguridad en Alturas, lineas de vida, puntos de anclaje"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:description" content="Nuestra empresa se especializa en la venta de servicios de ingeniería enfocados en líneas de vida y puntos de anclaje.ofrecemos una amplia gama, puntos de anclaje, lineas de vida, linea de vida horizontal, linea de vida vertical, línea de vida, lineas de vida verticales puntos de anclaje certificados"></meta>
        <meta itemprop="name" content="Industek Seguridad en Alturas, lineas de vida, puntos de anclaje"></meta>
        <meta itemprop="description" content="Nuestra empresa se especializa en la venta de servicios de ingeniería enfocados en líneas de vida y puntos de anclaje.ofrecemos una amplia gama, puntos de anclaje, lineas de vida, linea de vida horizontal, linea de vida vertical, línea de vida, lineas de vida verticales puntos de anclaje certificados"></meta>
        <meta name="twitter:title" content="Industek Seguridad en Alturas, lineas de vida, puntos de anclaje"></meta>
        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:description" content="Nuestra empresa se especializa en la venta de servicios de ingeniería enfocados en líneas de vida y puntos de anclaje.ofrecemos una amplia gama, puntos de anclaje, lineas de vida, linea de vida horizontal, linea de vida vertical, línea de vida, lineas de vida verticales puntos de anclaje certificados"></meta>


      </Helmet>
        <div style={{ backgroundImage: "url(/imagenes/Inicio/FONDO_01.webp)",backgroundRepeat: "no-repeat",backgroundPosition:"top",backgroundSize:"cover",zIndex: 1}}>
        <div style={{top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 1}}>
          <Header />
        </div>
      </div>
      <GridContent />
      <BannerImageIzq />
      <BannerImageDer 
        imagenURL="./imagenes/Inicio/Linea-de-vida-escaleras.webp"
        titulo="Líneas de Vida"
        descripcion="Protección continua, confianza asegurada. Nuestras líneas de vida garantizan seguridad en alturas sin compromisos. Confía en nuestra experiencia para mantener tus proyectos y personal protegidos."
        buttonText="¡Protege tu equipo ahora!"
        TituloImagen="Lineas de vida, Las mejores precios en linea de vida"
        urlBtn="https://api.whatsapp.com/send/?phone=573054138340&text=Buenos+Dias+estamos+interesados+en+conocer+mas+sobre+Linea+de+Vida+necesitamos+una+cotizaci%C3%B3n."/>
      <TextCentralColor />
      <IndustekSection
        title="INDUSTEK"
        subtitle="Descubre Nuestro Compromiso con la Excelencia"
        description="Nuestra misión es superar tus expectativas en cada proyecto. Desde obras civiles hasta soluciones integrales, estamos comprometidos con la excelencia y la satisfacción del cliente."
        question="¿Listo para dar forma a tu visión?"
        buttonText="Contactar ahora"
      />
      <Footer />
      <IconoWhatsapp />
    </div>
    
}
export default Inicio;
import React from 'react';
import './Grid2X2.css';

const GridSection = () => {
  return (
    <div className="contenedor-grid-section-Grid2X2">
      <h1 className="titulo-principal-Grid2X2">Tipos de anclajes fijos</h1>
      
      <div className="grid-container-Grid2X2">
        <div className="grid-item-Grid2X2">
          <h2 className="titulo-grid-Grid2X2">¿Que son los anclajes de hormigón? </h2>
          <p className="descripcion-grid-Grid2X2">Estos se instalan en estructuras de concreto y están diseñados para soportar cargas específicas. Pueden ser pernos, barras o placas insertadas en el concreto durante la construcción o mediante sistemas de anclaje químico.</p>
        </div>

        <div className="grid-item-Grid2X2">
          <h2 className="titulo-grid-Grid2X2">¿Que son los Anclajes estructurales?</h2>
          <p className="descripcion-grid-Grid2X2">Son diseñados para fijarse a elementos estructurales como vigas de acero, columnas o estructuras metálicas. Estos anclajes deben cumplir con estándares rigurosos de resistencia y durabilidad.</p>
        </div>

        <div className="grid-item-Grid2X2">
          <h2 className="titulo-grid-Grid2X2">¿Donde instalar anclajes para techos?</h2>
          <p className="descripcion-grid-Grid2X2">Especialmente diseñados para ser instalados en superficies de techos, estos anclajes proporcionan puntos de sujeción seguros para trabajadores que realizan tareas en alturas.</p>
        </div>

        <div className="grid-item-Grid2X2">
          <h2 className="titulo-grid-Grid2X2">¿Donde se usan los anclajes para fachadas?</h2>
          <p className="descripcion-grid-Grid2X2">Se utilizan en la parte exterior de edificios y estructuras para proporcionar puntos de anclaje seguros para trabajos en fachadas, limpieza de ventanas y mantenimiento de edificios.</p>
        </div>
      </div>
    </div>
  );
};

export default GridSection;

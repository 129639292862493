import React from 'react';
import './SeccionMitadImagenIZQTxTCentrado.css';
import './Boton.css'
//<a className='boton-seccion-TxT-Centrado btnColor' type="button" href={url} target='_Blank'>{textoBoton}</a>

const SeccionConImagen = ({ imagenURL, titulo, descripcion, textoBoton, url }) => {
  return (
    <div className="contenedor-seccion-TxT-Centrado">
      <div className="lado-derecho-TxT-Centrado">
        <h2 className="titulo-seccion-TxT-Centrado">{titulo}</h2>
        <p className="descripcion-seccion-TxT-Centrado">{descripcion}</p>
        <div>

        </div>
      </div>
      <div className="lado-izquierdo-TxT-Centrado">
        <img src={imagenURL} alt={titulo} className="imagen-seccion-TxT-Centrado" />
      </div>
    </div>
  );
};

export default SeccionConImagen;
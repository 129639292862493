import React from 'react';
import './SeccionCentradaConFondo.css';

const SeccionCentrada = ({ imagenFondo, titulo, descripcion, buttonText, pregunta, url }) => {
  return (
    <div className="contenedor-seccion-centrada" style={{ backgroundImage: `url(${imagenFondo})` }}>
      <div className="contenedortransparente">
        <div className="contenido-seccion-centrada">
            <h2 className="titulo-seccion-centrada">{titulo}</h2>
            <p className="descripcion-seccion-centrada">{descripcion}</p>
            <p className="descripcion-seccion-centrada">{pregunta}</p>
            <a className="boton-seccion-centrada btnColor" href={url} target='_Blank'>{buttonText}</a>
        </div>
      </div>
    </div>
  );
};

export default SeccionCentrada;

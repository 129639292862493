import { Outlet } from "react-router-dom";
import Barrasup from "../component/Menu"

const Menu = () =>{
    return<div>
        <Barrasup />
        
        <Outlet />
    </div>
}
export default Menu
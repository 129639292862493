import SeccionConImagen from "../component/SeccionMitadImagenDERTxTCentrado";
import BannerImageDer from "../component/BannerDerImageItem"
import CincoTxT1ImA from "../component/Componen5TxT1Ima"
import IndustekSection from '../component/TextoCentralBtn';
import Footer from '../component/Footer';
import IconoWhatsapp from "../component/IconoWhatsapp";
import TituloConFondo from "../component/TituloConFondo";
import { Helmet } from "react-helmet";

const SeguridadEnAlturas = () => {
    return <div>
      <Helmet>
      <title>Seguridad En Alturas anclajes lineas de vida industek</title>
      <link rel="canonical" href="http://industek.com.co/seguridad-en-alturas"></link>
      <meta property="og:url" content="http://industek.com.co/seguridad-en-alturas"></meta>
      <meta itemprop="url" content="http://industek.com.co/seguridad-en-alturas"></meta>
      <meta name="twitter:url" content="http://industek.com.co/seguridad-en-alturas"></meta>
      <meta name="description" content="Especialistas en Seguridad En Alturas Puntos de Anclaje Fijos, Líneas de Vida, Sistemas de Acceso, Inspección y Certificación, Tienda EPP y EPI"></meta>
      <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
      <meta property="og:title" content="Seguridad En Alturas anclajes lineas de vida industek"></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:description" content="Especialistas en Seguridad En Alturas Puntos de Anclaje Fijos, Líneas de Vida, Sistemas de Acceso, Inspección y Certificación, Tienda EPP y EPI"></meta>
      <meta itemprop="name" content="Seguridad En Alturas anclajes lineas de vida industek"></meta>
      <meta itemprop="description" content="Especialistas en Seguridad En Alturas Puntos de Anclaje Fijos, Líneas de Vida, Sistemas de Acceso, Inspección y Certificación, Tienda EPP y EPI"></meta>
      <meta name="twitter:title" content="Seguridad En Alturas anclajes lineas de vida industek"></meta>
      <meta name="twitter:card" content="summary"></meta>
      <meta name="twitter:description" content="Especialistas en Seguridad En Alturas Puntos de Anclaje Fijos, Líneas de Vida, Sistemas de Acceso, Inspección y Certificación, Tienda EPP y EPI"></meta>

      </Helmet>
      <TituloConFondo 
        titulo="Seguridad en Alturas" 
        imagenFondo="./imagenes/SeguridadAlturas/LineaDeVida56.jpeg" 
      />
        <SeccionConImagen
            imagenURL="./imagenes/SeguridadAlturas/Anclaje o linea.webp"
            titulo="Puntos de Anclaje Fijos"
            descripcion="Garantizamos puntos de anclaje seguros y confiables para tus trabajos en altura, cumpliendo con los estándares de seguridad más exigentes."
            textoBoton="Conoce Mas"
            url="/Puntos-de-Anclaje"
        />
        <BannerImageDer 
        imagenURL="./imagenes/SeguridadAlturas/lineenescaleras.jpeg"
        titulo="Líneas de Vida"
        descripcion="Protege a tu equipo con nuestras líneas de vida certificadas, diseñadas para ofrecer seguridad y tranquilidad en trabajos en altura."
        item1="Lineas de vida horizontales"
        item2="Lineas de vida verticales"
        buttonText="Solicitar Cotización"
        TituloImagen="Lineas de vida, Las mejores precios en linea de vida"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenos+Dias+estamos+interesados+en+conocer+mas+sobre+Linea+de+Vida."
        />
        <CincoTxT1ImA />
        <IndustekSection
        title="INDUSTEK"
        subtitle="Descubre Nuestro Compromiso con la Excelencia"
        description="Nuestra misión es superar tus expectativas en cada proyecto. Desde obras civiles hasta soluciones integrales, estamos comprometidos con la excelencia y la satisfacción del cliente."
        question="¿Listo para dar forma a tu visión?"
        buttonText="Contactar ahora"
        />
        <Footer/>
        <IconoWhatsapp />
    </div>;
}
export default SeguridadEnAlturas;
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-column">
          <h3 className="footer-title">INDUSTEK</h3>
          <p className="footer-description">
            Brindar soluciones de seguridad en alturas y mantenimientos en infraestructura con propuestas de valor que satisfacen la necesidad en el preciso momento.
          </p>
          <div className="footer-social">
            <a href="#" className="social-link">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#" className="social-link">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#" className="social-link">
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading">PRINCIPAL</h4>
          <ul className="footer-links">
            <li><a href="/">INICIO</a></li>
            <li><a href="/obrasciviles">OBRAS CIVILES</a></li>
            <li><a href="/serviciosdeingenieria">SERVICIOS INGENIERÍA</a></li>
            <li><a href="/seguridadenalturas">SEGURIDAD EN ALTURAS</a></li>
            <li><a href="/contacto">CONTACTO</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4 className="footer-heading">CONTACTO</h4>
          <p><a href="https://api.whatsapp.com/send/?phone=573054138340">Línea Comercial 305 413 8340</a></p>
          <p><a href="https://api.whatsapp.com/send/?phone=573138627031">Líneas de Atención 313 862 7031</a></p>
          <p>ingenieria@industek.com.co</p>
          <p>Cra. 12g N 18a 14 Bogotá, Colombia</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>COPYRIGHT © 2024. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

import SeccionMitadDeImagenLadoIZQ from "../component/SeccionMitadDeImagenLadoIZQ";
import SeccionMitadDeImagenLadoDER from "../component/SeccionMitadDeImagenLadoDER";
import SeccionCentrada from "../component/SeccionCentradaConFondo";
import Pie from "../component/Footer"
import IconoWhatsapp from "../component/IconoWhatsapp";
import TituloConFondo from "../component/TituloConFondo";
import { Helmet } from "react-helmet";

const ObrasCiviles = () => {
    return <div> 
    <Helmet>
    <title>Construyendo Seguridad: Industek en Obras Civiles</title>
    <link rel="canonical" href="http://industek.com.co/obras-civiles"></link>
    <meta property="og:url" content="http://industek.com.co/obras-civiles"></meta>
    <meta itemprop="url" content="http://industek.com.co/obras-civiles"></meta>
    <meta name="twitter:url" content="http://industek.com.co/obras-civiles"></meta>
    <meta name="description" content="Industek presenta los servicio de Obras civiles como Impermeabilización y Sellado de Estructuras, Fabricación y Construcción de Estructuras, Mantenimiento y/o Limpieza de Fachadas, Mantenimiento de Áreas Comunes, Suministro e Instalación de Vidrio y/o Policarbonato,"></meta>
    <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
    <meta property="og:title" content="Construyendo Seguridad: Industek en Obras Civiles"></meta>
    <meta property="og:type" content="website"></meta>
    <meta property="og:description" content="Industek presenta los servicio de Obras civiles como Impermeabilización y Sellado de Estructuras, Fabricación y Construcción de Estructuras, Mantenimiento y/o Limpieza de Fachadas, Mantenimiento de Áreas Comunes, Suministro e Instalación de Vidrio y/o Policarbonato"></meta>
    <meta itemprop="name" content="Construyendo Seguridad: Industek en Obras Civiles"></meta>
    <meta itemprop="description" content="Industek presenta los servicio de Obras civiles como Impermeabilización y Sellado de Estructuras, Fabricación y Construcción de Estructuras, Mantenimiento y/o Limpieza de Fachadas, Mantenimiento de Áreas Comunes, Suministro e Instalación de Vidrio y/o Policarbonato"></meta>
    <meta name="twitter:title" content="Construyendo Seguridad: Industek en Obras Civiles"></meta>
    <meta name="twitter:card" content="summary"></meta>
    <meta name="twitter:description" content="Industek presenta los servicio de Obras civiles como Impermeabilización y Sellado de Estructuras, Fabricación y Construcción de Estructuras, Mantenimiento y/o Limpieza de Fachadas, Mantenimiento de Áreas Comunes, Suministro e Instalación de Vidrio y/o Policarbonato"></meta>

    </Helmet>
    <TituloConFondo 
        titulo="Obras Civiles" 
        imagenFondo="./imagenes/ObraCivil/ChicaIng2.webp" 
    />
    <SeccionMitadDeImagenLadoIZQ
        imagenURL="./imagenes/ObraCivil/EstructuraPara2.webp"
        titulo="Impermeabilización y Sellado de Estructuras"
        descripcion="Protege tus estructuras contra la humedad y los elementos. Utilizamos técnicas avanzadas y materiales de alta calidad para garantizar resultados duraderos."
        
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+Impermeabilizante+y+Sellado+de+Estructuras+quiero+una+cotizaci%C3%B3n."
      />
    <SeccionMitadDeImagenLadoDER
        imagenURL="./imagenes/ObraCivil/3DEstructura.webp"
        titulo="Fabricación y Construcción de Estructuras"
        descripcion="Desde el diseño hasta la construcción, materializamos tus proyectos de estructuras con precisión y eficiencia. Nuestro equipo experimentado garantiza resultados que superan tus expectativas."
        textoBoton="Explorar servicios"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+fabricaci%C3%B3n+y+construcci%C3%B3n+de+estructuras+quiero+una+cotizaci%C3%B3n."
      />
    <SeccionMitadDeImagenLadoIZQ
        imagenURL="./imagenes/ObraCivil/MantenimientoLimpiezadeFachadas2.webp"
        titulo="Mantenimiento y/o Limpieza de Fachadas"
        descripcion="Mantén la apariencia y funcionalidad de tus edificios con nuestro servicio de mantenimiento y limpieza de fachadas. Preservamos la integridad estética y estructural de tus instalaciones."
        textoBoton="Conocer más"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+mantenimiento+y/o+limpieza+de+fachadas+quiero+una+cotizaci%C3%B3n."
      />
    <SeccionMitadDeImagenLadoDER
        imagenURL="./imagenes/ObraCivil/MantenimientoComunes2.webp"
        titulo="Mantenimiento de Áreas Comunes"
        descripcion="Crea entornos seguros y acogedores para tus espacios compartidos con nuestro servicio de mantenimiento de áreas comunes. Mejora la calidad de vida de tus residentes y usuarios."
        textoBoton="Descubrir más"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+mantenimiento+de+%C3%A1reas+comunes+para+la+elaboraci%C3%B3n+de+cotizaci%C3%B3n."
      />
    <SeccionMitadDeImagenLadoIZQ
        imagenURL="./imagenes/ObraCivil/divisiones-para-banos-bogota.webp"
        titulo="Suministro e Instalación de Vidrio y/o Policarbonato"
        descripcion="Transforma tus espacios con luz y transparencia. Ofrecemos soluciones de vidrio y policarbonato que combinan funcionalidad y estética para tus proyectos."
        textoBoton="Ver detalles"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+suministro+e+instalaci%C3%B3n+de+vidrio+y/o+policarbonato+para+la+elaboraci%C3%B3n+de+cotizaci%C3%B3n."
      />
    <SeccionCentrada 
        imagenFondo="./imagenes/ObraCivil/PuntodeAnclaje.jpeg"
        titulo="Descubre Nuestro Compromiso con la Excelencia"
        descripcion="Nuestra misión es superar tus expectativas en cada proyecto. Desde obras civiles hasta soluciones integrales, estamos comprometidos con la excelencia y la satisfacción del cliente."
        pregunta="¿Listo para dar forma a tu visión?"
        buttonText="Contactar ahora"
        url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+Tardes+quiero+conocer+mas+de+sus+servicios."

      />
    <Pie/>
    <IconoWhatsapp />
    </div>;
}
export default ObrasCiviles;
import React from 'react';
import './BannerDerImage.css';

const Banner = ({ titulo, descripcion,item1, item2, buttonText,imagenURL, TituloImagen, url }) => {
  return (
    <div className="BannerDerImagen-container Padd90">
      <div className="BannerDerImagen-content">
        <h1 className="BannerDerImagen-title">{titulo}</h1>
        <p className="BannerDerImagen-description">{descripcion}</p>
        <div className="BannerDerImagen-item">{item1}</div>
        <div className="BannerDerImagen-item">{item2}</div>
        <a href={url} className="BannerDerImagen-button btnColor" target='_Blanck'>{buttonText}</a>
      </div>
      <div className="BannerDerImagen-image">
        <img src={imagenURL} alt={TituloImagen} />
      </div>
      
    </div>
  );
}

export default Banner;
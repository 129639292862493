import React from 'react';
import './IconoWhatsapp.css'; // Archivo CSS para estilos

const IconoWhatsapp = () => {
  return (
    <a
      href="https://wa.me/573054138340" // Reemplaza con tu número de WhatsApp
      className="whatsapp-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Ícono de WhatsApp
        alt="WhatsApp"
      />
    </a>
  );
};

export default IconoWhatsapp;

import './Divi2Contacto.css';
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactoSeccion = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    asunto: '',
    mensaje: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
  
    // Aquí ingresas tus datos de EmailJS
    const serviceId = 'service_b1m0vbm';
    const templateId = 'template_ji04ami';
    const userId = 'D2rpyJJencTYrCFVi';
  
    const mess = {
      nombre: formData.nombre,
      correo: formData.correo,
      asunto: formData.asunto,
      message: formData.mensaje,
    };
    console.log(mess); // Verificar los datos del formulario
    //emailjs.send(serviceId, templateId,{message: "Envio desde codigo"},userId)
    emailjs.send(serviceId, templateId,mess ,userId)
      .then((response) => {
        alert('Mensaje enviado correctamente');
        setFormData({
          nombre: '',
          correo: '',
          asunto: '',
          mensaje: '',
        });
      })
      .catch((error) => {
        alert('Error al enviar el mensaje', error);
      });
  };
  


  return (
    <div className="contenedor-contacto">
      <div className="columna-contacto texto-contacto">
        <h2>Contacta con nosotros</h2>
        <h3>
          <div>Teléfono: <div className='textrojo'>313 862 7031</div></div>
          <div>Teléfono: <div className='textrojo'>305 413 8340</div></div>
          <div>Email: <div className='textrojo'>ingenieria@industek.com.co</div></div>  
        </h3>
        <p>Atención personalizada para tu proyecto. ¡Escríbenos y cuéntanos tu visión!</p>
        <form className="formulario-contacto" onSubmit={handleSubmit}>
          <input
            type="text"
            name="nombre"
            placeholder="Nombre"
            value={formData.nombre}
            onChange={handleChange}
            required // Hacer obligatorio el campo
          />
          <input
            type="email"
            name="correo"
            placeholder="Correo"
            value={formData.correo}
            onChange={handleChange}
            required // Hacer obligatorio el campo
          />
          <input
            type="text"
            name="asunto"
            placeholder="Numero De Telefono"
            value={formData.asunto}
            onChange={handleChange}
            required // Hacer obligatorio el campo
          />
          <textarea
            name="mensaje"
            placeholder="Servicio De Interes"
            value={formData.mensaje}
            onChange={handleChange}
            required // Hacer obligatorio el campo
          />
          <button className='BtnEnviar' type="submit">Enviar</button>
        </form>
      </div>

      <div className="columna-contacto imagen-contacto">
        <img src="./logo canal alfa  industek.png" alt="Imagen Contacto" />
      </div>
    </div>
  );
};

export default ContactoSeccion;

import React from 'react';
import './CentralTxTColor.css';

const Textocentrado = () => {
  return (
   <div className="containerTextCentral"><img src='https://content.app-sources.com/s/24444282803329/uploads/a-products/stars-7250417.png?format=webp' className='Textocentrado-text-image'/>
      
    <div className="Textocentrado-text-container">
      <p className="Textocentrado-text">
        En <span className="TextoColor">Industek</span> contamos con un equipo experto en 
        <span className="TextoColor"> ingeniería</span> y <span className="TextoColor">seguridad en alturas</span>. 
        Comprometidos con la <span className="TextoColor">excelencia</span> y la 
        <span className="TextoColor"> seguridad</span> en cada proyecto.
      </p>
    </div>
   </div> 
  );
};

export default Textocentrado;

import React from 'react';
import './TxTCentrado.css'; // Asegúrate de que este archivo esté correctamente enlazado

const IndustekSection = ({titulo, empresa, descripcion}) => {
  return (
    <div className="contenedor-industek-TxTCentrado">
      <h1 className="titulo-pequeno-TxTCentrado">{empresa}</h1>
      <h2 className="titulo-grande-TxTCentrado">{titulo}</h2>
      <p className="descripcion-TxTCentrado">{descripcion}</p>
    </div>
  );
};

export default IndustekSection;

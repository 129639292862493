import React from 'react';
import './TituloConFondo.css'; // Archivo CSS para los estilos

const TituloConFondo = ({ titulo, imagenFondo }) => {
  return (
    <div
      className="fondo-imagen colorcalido"
      style={{ backgroundImage: `url(${imagenFondo})` }} // Imagen de fondo como prop
    >
      <div className="contenedor-titulo opacidad">
        <h1 className="titulo">{titulo}</h1>
      </div>
    </div>
  );
};

export default TituloConFondo;

// src/components/Menu.js
import React, { useState } from 'react';
import './Menu.css';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (<div>
    
    <div className="menu">
    {/* Logotipo a la izquierda */}
    <div>
      <a href='/'><img className="menu-logo" src="./logo canal alfa  industek.png" alt="Logotipo" /></a>
    </div>
  
    {/* Menú hamburguesa solo para móviles */}
    <button className="menu-button" onClick={toggleMenu}>
        ☰
      </button>
  
    {/* Elementos del menú a la derecha */}
    <ul className={`menu-list ${isMenuOpen ? 'active' : ''}`}>
    <li className="menu-item"><a href="/">INICIO</a></li>
        <li className="menu-item"><a href="/ObrasCiviles">OBRAS CIVILES</a></li>
        <li className="menu-item"><a href="/ServiciosDeIngenieria">SERVICIOS INGENIERÍA</a></li>
        <li className="menu-item"><a href="/SeguridadEnAlturas">SEGURIDAD EN ALTURAS</a></li>
        <li className="menu-item"><a href="/Contacto">CONTACTO</a></li>
    </ul>
  </div>
</div>  
  );
};

export default Menu;
